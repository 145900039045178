<template>
  <div
    class="tips__filters-container"
    :style="{
      'flex-direction': isWidget ? 'column' : 'column-reverse',
    }"
  >
    <div v-if="false" class="tips__filters-container__filter-item">
      <BIcon v-show="false" icon="heart-outline" size="is-medium" />
    </div>
    <!--  Scientific Tips section  -->
    <div v-if="!isWidget" class="tips__scientific-tips" @click="onScientificTipsClicked(true)">
      <div class="tips__scientific-tips__user-type is-hidden-mobile">
        <ButtonUserType v-if="displayWidth > 822" @clicked="onClickUserType" />
      </div>
      <div class="tips__scientific-tips__title" @click="onScientificTipsClicked(false)">
        Tips Científicos para apostar
        <BIcon
          class="tips__scientific-tips__title__icon-info"
          icon="information-outline"
          :size="displayWidth < 525 ? 'is-small' : 'is-medium'"
          :style="iconPosition"
        />
      </div>
      <div class="tips__scientific-tips__description">Aumenta tus probabilidades de ganar</div>
    </div>
    <!--  Videos section  -->
    <div
      class="tips__videos"
      :style="{
        'justify-content': user.premiumAccount || isWidget ? 'space-between' : 'center',
        'align-items': isWidget ? 'flex-start' : '',
        'flex-flow': isWidget ? 'row' : '',
      }"
    >
      <!--   Inputs to set amount and select risk   -->
      <div v-if="user.premiumAccount || isWidget" class="tips__videos__inputs">
        <!--    Bet amount    -->
        <div>
          <div class="tips__videos__inputs__info" @click="onClickSiApuestasLaCantidadDe">
            Si apuestas la cantidad de
            <BIcon :icon="getInfoIcon" size="is-small" />
          </div>
          <div class="tips__videos__inputs__input total-bet">
            <div class="total-bet-amount">
              <div class="total-bet-symbol">$</div>
              <div class="total-bet-input">
                <input
                  title=""
                  :disabled="!currentTeams.length"
                  type="number"
                  @keydown="onKeydownTotalBetAmount"
                  v-model="totalBetAmount"
                  @change="onChangeTotalAmountBet"
                  @click="selectAllAmount"
                  ref="totalAmount"
                  min="0"
                  @blur="checkIfMinimum"
                />
              </div>
              <div class="total-bet-abbreviation">{{ currencyAbbreviation }}</div>
            </div>
          </div>
        </div>
        <!--    Bet risk    -->
        <div>
          <div class="tips__videos__inputs__info" @click="onClickYEligesUnRiesgo">
            Eliges un riesgo
            <BIcon :icon="getInfoIcon" size="is-small" />
          </div>
          <div class="tips__videos__inputs__input">
            <BSelect
              :disabled="!currentTeams.length"
              placeholder="Selecciona uno"
              :value="selectedRisk"
              expanded
              @input="setCurrentRisk"
            >
              <option v-for="option in riskTypes" :key="option.id" :value="option.id">
                {{ option.name }}
              </option>
            </BSelect>
          </div>
        </div>
        <!--    Tournament    -->
        <div>
          <div class="tips__videos__inputs__info" style="cursor: default">
            Y eliges un torneo
            <BIcon :icon="getInfoIcon" size="is-small" style="display: none" />
          </div>
          <div class="tips__videos__inputs__input">
            <BSelect
              :disabled="!currentTeams.length"
              placeholder="Selecciona uno"
              :value="selectedTournament"
              expanded
              @input="onTournamentChanged"
            >
              <option v-for="tournament in tournaments" :key="tournament.id" :value="tournament">
                {{ tournament.name }}
              </option>
            </BSelect>
          </div>
        </div>
        <div v-if="isWidget" class="percent-n-info">
          <!-- <span v-if="displayWidth >= 1024">=</span> -->
          <div class="percent-n-info__percent">
            <span>{{ premiumPercentage }}%</span>
          </div>
          <div class="percent-n-info__info">
            <h4>de ganar dinero</h4>
            <span>Para lograrlo debes colocar las 4 apuestas de forma independiente (no es parlay)</span>
          </div>
        </div>
      </div>
      <!--   User type and user tip   -->
      <div class="tips__videos__user-type" v-if="displayWidth < 822 && !isWidget">
        <hr v-if="user.premiumAccount || isWidget" />
        <div
          class="tips__videos__user-type__tip"
          :style="{ 'padding-bottom': user.premiumAccount || isWidget ? '10px' : '0' }"
        >
          <div v-if="user.premiumAccount || isWidget" class="tips__videos__user-type__tip__text">
            TIP ESPECIAL PARA:
          </div>
          <ButtonUserType @clicked="onClickUserType" />
        </div>
        <hr v-if="user.premiumAccount || isWidget" />
      </div>
      <!--   Buttons to display videos   -->
      <div v-if="!isWidget" class="tips__videos__buttons">
        <div class="button button-video is-rounded" @click="onClickQueEsGolStats">
          <span>¿Qué es GolStats?</span>
          <span class="icon">
            <i class="mdi mdi-play-circle-outline"></i>
          </span>
        </div>
        <div class="button button-video is-rounded" @click="onClickComoFunciona">
          <span>¿Cómo funciona?</span>
          <span class="icon">
            <i class="mdi mdi-play-circle-outline"></i>
          </span>
        </div>
      </div>
    </div>
    <!--  Top Bets info  -->
    <div v-if="!user.premiumAccount && !isWidget" class="tips__top-bets">
      <div class="tips__top-bets__text" @click="onClickTop4ApuestasDelMomento">
        top 4 apuestas del momento
        <BIcon
          class="tips__top-bets__text__icon-info"
          icon="information-outline"
          :size="displayWidth < 525 ? 'is-small' : 'is-normal'"
        />
      </div>
      <div class="tips__top-bets__risk-selector">
        <div class="tips__filters-container__filter-item is-block">
          <BSelect
            :disabled="!currentTeams.length"
            placeholder="Selecciona uno"
            :value="selectedRisk"
            expanded
            @input="setCurrentRisk"
          >
            <option v-for="option in riskTypes" :key="option.id" :value="option.id">
              {{ option.name }}
            </option>
          </BSelect>
        </div>
        <div class="tips__filters-container__filter-item is-block">
          <BSelect
            :disabled="!currentTeams.length"
            placeholder="Selecciona uno"
            :value="selectedTournament"
            expanded
            @input="onTournamentChanged"
          >
            <option v-for="tournament in tournaments" :key="tournament.id" :value="tournament">
              {{ tournament.name }}
            </option>
          </BSelect>
        </div>
      </div>
    </div>
    <!--  Strategy info  -->
    <div v-else class="tips__strategy">
      <div class="tips__strategy__text" @click="onClickVerMasInformacionDeEstrategiaDeCombinacionDeApuestas">
        estrategia de combinacion de apuestas
        <BIcon class="tips__strategy__text__icon-info" icon="information-outline" />
      </div>
    </div>
    <div v-show="false" class="tips__filters-container__filter-item">
      <BField class="is-uppercase" label="tipo de apuesta">
        <BSelect placeholder="Selecciona una" :value="selectedBet" @click="setSelectedBet">
          <option v-for="option in betTypes" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </BSelect>
      </BField>
      <div class="has-divider-vertical" />
    </div>
    <div v-show="false" class="tips__filters-container__filter-item" style="min-width: 146px;">
      <BField class="is-uppercase" label="equipos">
        <BSelect placeholder="Seleccione uno" :value="selectedTeams" @input="setSelectedTeams">
          <option v-for="option in teams" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </BSelect>
      </BField>
      <div class="has-divider-vertical" style="border-left: white" />
    </div>
  </div>
</template>

<script>
import { riskFreeTypes, riskPremiumTypes, betTypes, teams } from '@/data/tipsFiltersOptions';
import { mapState, mapGetters, mapMutations } from 'vuex';
import ButtonUserType from '@/components/Elements/buttons/ButtonUserType';
import { BIcon } from 'buefy/dist/esm/icon';
import { BSelect } from 'buefy/dist/esm/select';
import { BField } from 'buefy/dist/esm/field';

import {
  clickSelectTipsRiesgo,
  widgetApostadoresTipsSelectTorneo,
  clickInputTipsMontoTotalApostar,
  clickTipsCientificosParaApostar,
  clickQueEsGolStats,
  clickComoFunciona,
  clickTop4ApuestasDelMomento,
  clickTipsUsuarioFree,
  clickTipsVerMasInformacionDeMonto,
  clickVerMasInformacionDeTiposDeRiesgo,
  clickVerMasInformacionDeEstrategiaDeCombinacionDeApuestas,
} from '@/utils/analytics';

export default {
  name: 'TipsFilter',
  components: {
    ButtonUserType,
    BIcon,
    BSelect,
    BField,
  },
  props: {
    isWidget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      riskFreeTypes: riskFreeTypes,
      riskPremiumTypes: riskPremiumTypes,
      betTypes: betTypes,
      teams: teams,
      currencyAbbreviation: 'mxn',
      oldBet: 0,
      localBetAmount: 0,
    };
  },
  computed: {
    ...mapState('tips', [
      'selectedRisk',
      'selectedBet',
      'selectedTeams',
      'betAmount',
      'currentTeams',
      'tournamentsToSelect',
    ]),
    ...mapState('loginGeneral', ['user']),
    ...mapState('tips', ['selectedTournament']),
    ...mapState(['displayWidth']),
    ...mapGetters('tips', ['getUserType', 'premiumPercentage']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    riskTypes() {
      return this.user.premiumAccount || this.isWidget ? this.riskPremiumTypes : this.riskFreeTypes;
    },
    tournaments() {
      return this.tournamentsToSelect;
    },
    totalBetAmount: {
      get() {
        return this.betAmount;
      },
      set(nv) {
        if (nv !== this.oldBet) {
          this.localBetAmount = nv;
        } else {
          this.$refs.totalAmount.value = this.oldBet;
        }
      },
    },
    iconPosition() {
      return this.$mq === 'mobile'
        ? this.displayWidth < 525
          ? { right: '-25px', top: '4px' }
          : { right: '-40px' }
        : { left: '-40px' };
    },
    getInfoIcon() {
      return this.displayWidth < 525 ? 'information' : 'information-outline';
    },
  },
  watch: {
    localBetAmount(nv, ov) {
      if (nv !== ov && this.$refs.totalAmount.validity.valid) {
        if (nv >= 1) {
          this.setBetAmount(Number(nv));
          this.updateBets();
          this.setCurrentTeams();
          this.oldBet = Number(nv);
        } else {
          this.setBetAmount(1);
          this.updateBets();
          this.setCurrentTeams();
          this.oldBet = 1;
        }
      } else {
        this.$refs.totalAmount.value = this.oldBet;
      }
      this.toggleLoading();
    },
    totalBetAmount(nv) {
      if (nv === 0) {
        this.totalBetAmount = 100;
        this.setBetAmount(100);
        this.updateBets();
        this.setCurrentTeams();
        this.oldBet = 100;
      }
    },
    riskTypes: {
      deep: true,
      handler(newValue) {
        this.setSelectedRisk(newValue[0].id);
      },
    },
  },
  methods: {
    ...mapMutations('tips', [
      'setSelectedRisk',
      'setSelectedBet',
      'setSelectedTeams',
      'setBetAmount',
      'setCurrentTeams',
      'updateBets',
      'toggleLoading',
      'setSelectedTournament',
      'setInitialScenarios',
      'setScenarios',
    ]),
    ...mapMutations('general', ['setVideoModalVisibility', 'setVideoSrc']),
    ...mapMutations(['SET_PREMIUM_MESSAGE', 'setModalInfoVisible']),
    setCurrentRisk($val) {
      let selectedRisk = this.riskTypes.find(r => r.id === $val).name;
      clickSelectTipsRiesgo(this.getPremiumAccount, selectedRisk, this.getIsGolstats);
      this.setSelectedRisk($val);
      this.setCurrentTeams();
      this.toggleLoading();
    },
    onChangeTotalAmountBet(event) {
      clickInputTipsMontoTotalApostar(this.getPremiumAccount, Number(event.target.value), this.getIsGolstats);
    },
    onKeydownTotalBetAmount(event) {
      const disponibleKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];
      if (event.key == '-' || event.key == '+' || (isNaN(event.key) && !disponibleKeys.includes(event.key))) {
        event.preventDefault();
      }
    },
    showVideo() {
      this.setVideoSrc('https://az740894.vo.msecnd.net/golstats-bets/tips.mp4');
      this.setVideoModalVisibility(true);
    },
    selectAllAmount() {
      this.$refs.totalAmount.select();
    },
    checkIfMinimum() {
      if (this.$refs.totalAmount.value <= 0) {
        this.totalBetAmount = 100;
      }
    },
    onScientificTipsClicked(isFromContainer) {
      if (isFromContainer && this.displayWidth > 768) {
        return;
      }
      clickTipsCientificosParaApostar(this.getPremiumAccount, this.getIsGolstats);
      this.setModalInfoVisible({
        isVisible: true,
        title: 'GOLSTATS',
        componentName: 'ScientificTipsInfo',
      });
    },
    onClickQueEsGolStats() {
      clickQueEsGolStats(this.getPremiumAccount, this.getIsGolstats);
      this.setVideoModalVisibility(true);
    },
    onClickComoFunciona() {
      clickComoFunciona(this.getPremiumAccount, this.getIsGolstats);
      this.showVideo();
    },
    onClickTop4ApuestasDelMomento() {
      clickTop4ApuestasDelMomento(this.getPremiumAccount, this.getIsGolstats);
      this.setModalInfoVisible({
        isVisible: true,
        title: 'TOP 4 APUESTAS EN ESTE MOMENTO',
        componentName: 'TopBetsInfo',
      });
    },
    onClickUserType(userType) {
      let type = userType.replace(' ', '_').toLowerCase();
      if (type === 'usuario_free') {
        clickTipsUsuarioFree(this.getPremiumAccount, this.getIsGolstats);
      }
    },
    onClickSiApuestasLaCantidadDe() {
      clickTipsVerMasInformacionDeMonto(this.getPremiumAccount, this.getIsGolstats);
      this.setModalInfoVisible({
        isVisible: true,
        title: 'MONTO',
        componentName: 'AmountInfo',
      });
    },
    onClickYEligesUnRiesgo() {
      clickVerMasInformacionDeTiposDeRiesgo(this.getPremiumAccount, this.getIsGolstats);
      this.setModalInfoVisible({
        isVisible: true,
        title: 'RIESGO',
        componentName: 'RiskInfo',
      });
    },
    onClickVerMasInformacionDeEstrategiaDeCombinacionDeApuestas() {
      clickVerMasInformacionDeEstrategiaDeCombinacionDeApuestas(this.getPremiumAccount, this.getIsGolstats);
      this.setModalInfoVisible({
        isVisible: true,
        title: 'ESTRATEGIA',
        componentName: 'StrategyInfo',
      });
    },
    onTournamentChanged(tournament) {
      widgetApostadoresTipsSelectTorneo({
        value: tournament.name,
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
      this.setSelectedTournament(tournament);
      this.setInitialScenarios();
      this.setScenarios();
      this.setCurrentTeams();
    },
  },
  mounted() {
    this.oldBet = Number(this.betAmount);
  },
};
</script>

<style lang="scss" scoped>
$color-info: #6889bf;
$color-grey-7f: #7f7f7f;

.tips__filters-container {
  color: #414141;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 10px;
  justify-content: flex-start;

  .tips {
    &__scientific-tips {
      width: 100%;
      border: solid 1.9px $color-grey-7f;
      border-radius: 7px;
      font-family: 'Avenir-Regular', sans-serif;
      position: relative;
      padding: 0 55px;

      &__user-type {
        position: absolute;
        top: 1rem;
        right: 14px;
      }

      &__title {
        font-family: Avenir-Pro-Bold;
        margin: 0 auto;
        width: fit-content;
        position: relative;
        font-size: 1.75rem;
        color: #3c3c3c;

        &__icon-info {
          position: absolute;
          top: 3px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &__description {
        width: fit-content;
        margin: 0 auto;
      }
    }

    &__videos {
      width: 100%;
      display: flex;
      margin: 10px 0;
      align-items: flex-end;
      flex-wrap: wrap;

      &__inputs {
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 1254px) {
          justify-content: center;
        }

        & > div {
          width: 220px;
          margin: 0 5px;

          & > div {
            width: 100%;
          }
        }

        &__info {
          text-align: center;
          font-size: 0.9rem;
          font-family: AvenirNextLTPro, sans-serif;
          color: white;
          font-weight: 500;
          background-color: $color-info;
          border-radius: 2px;
          padding: 4px 0;
          cursor: pointer;
        }

        &__input {
          margin-top: 2px;
        }
      }

      &__user-type {
        width: 100%;

        & > hr {
          margin: 0;
          border-top: 1px dotted #979797;
        }

        &__tip {
          display: flex;
          justify-content: center;
          align-items: center;

          &__text {
            margin-right: 1rem;
          }
        }
      }

      &__buttons {
        display: flex;
        flex-wrap: wrap;

        & > div {
          margin: 0 9px;
        }
      }
    }

    &__top-bets {
      width: 100%;
      border: solid 1.9px $color-grey-7f;
      border-radius: 4px;
      position: relative;
      padding: 10px 34px 8px;
      font-family: Circular-Std-Medium, sans-serif;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__text {
        position: relative;
        width: fit-content;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: 0.26px;
        cursor: pointer;

        &__icon-info {
          position: absolute;
          top: 0;
          right: -30px;
        }
      }

      &__risk-selector {
        display: flex;

        & > div {
          width: 350px;
          &:first-child {
            margin-right: 10px;
          }
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }

    &__strategy {
      width: 100%;
      border: solid 1.9px $color-grey-7f;
      border-radius: 4px;
      position: relative;
      padding: 10px 34px 8px;
      font-family: Circular-Std-Medium, sans-serif;
      cursor: pointer;

      &__text {
        position: relative;
        width: fit-content;
        margin: 0 auto;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: 0.26px;

        &__icon-info {
          position: absolute;
          top: 0;
          right: -30px;
        }
      }
    }
  }

  &__filter-item {
    margin: 0.2rem 0;
    display: flex;
    align-items: center;
  }

  & .field {
    & .select {
      font-size: small;
    }

    & > label {
      font-size: x-small;
      text-transform: uppercase;
    }
  }

  .button-video {
    color: white;
    background-color: #428ee6;
    font-size: 1rem;
    padding: 20px 30px;
    font-family: Circular-Std-Bold, sans-serif;
    .icon {
      margin-left: 10px !important;
      i {
        font-size: 1.5em;
      }
    }
  }
}

.risk-selector {
  max-width: fit-content;
  & .control:nth-child(1) {
    span.select {
      width: 85%;
    }
  }
}

.selected-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 50px;

  .selected-user-title {
    width: 175px;
    height: 15px;
    margin: 0 4px 4px 6px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
  }
}

.risk-selector,
.risk-selector .control {
  max-width: fit-content;
}

.total-bet {
  & .total-bet-title {
    width: 204px;
    height: 15px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    text-align: left;
  }
  & .total-bet-amount {
    display: flex;
    height: 36px;
    padding: 0 44px 0 20px;
    border-radius: 3.5px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    align-items: flex-end;

    & .total-bet-symbol {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 2px;
    }

    & .total-bet-symbol,
    & .total-bet-input {
      width: 80px;
      height: 27px;
      margin: auto;
      font-size: 20px;
      font-size: initial;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.11px;
      color: #3e3e3e;
    }

    & .total-bet-input {
      margin-right: 5px;
      & input {
        border: none;
        width: inherit;
        font-size: 19px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.11px;
        color: #3e3e3e;
        vertical-align: middle;
        height: 94%;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
      input:not(output):-moz-ui-invalid {
        box-shadow: none !important;
      }
    }

    & .total-bet-abbreviation {
      font-weight: 400;
      margin-bottom: 4px;
    }
  }
}

@media only screen and (max-width: 1216px) {
  .risk-selector,
  .risk-selector .control {
    max-width: 215px;
  }
}

@media only screen and (max-width: 1214px) {
  .tips__filters-container {
    .tips {
      &__videos {
        flex-direction: column-reverse;
        align-items: center;

        &__inputs {
          margin-top: 5px;
        }

        &__user-type {
          margin: 5px 0;

          &__tip {
            padding: 10px 0;
          }
        }

        &__buttons {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1182px) {
  .tips__filters-container .tips__top-bets {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0;
    margin-bottom: 10px;

    &__text {
      position: relative;
      width: fit-content;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 0.26px;

      &__icon-info {
        position: absolute;
        top: 2px;
        right: -30px;
      }
    }

    &__risk-selector {
      position: relative;
      top: 0;
      right: 0;
    }
  }
  .risk-selector,
  .risk-selector .control {
    max-width: 312px;
  }
}

@media only screen and (max-width: 766px) {
  .tips__filters-container .tips__top-bets {
    padding: 10px 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0;
    margin-bottom: 10px;

    &__text {
      position: relative;
      width: fit-content;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 0.26px;

      &__icon-info {
        position: absolute;
        top: 2px;
        right: -30px;
      }
    }

    &__risk-selector {
      margin-top: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      & > div {
        width: 100%;
        &:first-child {
          margin-right: 0;
        }
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 790px) {
  .tips__filters-container {
    .tips {
      &__strategy {
        background-color: #406299;
        color: white;

        &__text {
          font-family: Circular-Std-Medium, sans-serif;
          font-weight: 500;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .tips__filters-container {
    .tips {
      &__scientific-tips {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 736px) {
  .tips__filters-container {
    .tips {
      &__scientific-tips {
        padding: 0 32px;

        &__title {
          font-size: 17px;
        }

        &__description {
          font-size: 12px;
        }
      }

      &__videos {
        &__inputs {
          margin-top: 2.5px;

          & > div {
            display: flex;
            width: 100%;
            align-items: center;
            margin: 2.5px 0;

            & > .tips__videos__inputs__info {
              position: relative;
              width: 80%;
              margin: 0 5px 0 14px;

              & .icon {
                position: absolute;
                color: $color-info;
                top: 5px;
                left: -18px;
              }
            }

            & .total-bet {
              width: 120px;
            }

            & .total-bet-symbol {
              width: fit-content;
            }

            & .total-bet-amount {
              width: fit-content;
              padding: 0 5px;
            }

            & .total-bet-abbreviation {
              display: none;
            }
          }
        }

        &__user-type {
          margin: 2.5px;

          &__tip__text {
            font-size: 12px;
          }
        }

        &__buttons {
          justify-content: center;
          margin-bottom: 2.5px;

          & > .button-video {
            font-size: 0.8rem;
            padding: 15px 25px;
            margin: 2.5px 5px;
          }
        }
      }

      &__top-bets {
        &__text {
          font-size: 14px;
        }
      }

      &__strategy {
        &__text {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 391px) {
  .tips__filters-container {
    .tips {
      &__videos {
        &__buttons {
          justify-content: center;
          margin-bottom: 2.5px;

          & > .button-video {
            font-size: 11px;
            padding: 15px 15px;
            margin: 2.5px 5px 0;
          }
        }
      }
    }
  }
}

.percent-n-info {
  display: flex;
  flex-flow: row nowrap;
  width: auto !important;
  padding: 0 0.6rem;
  font-size: 1rem;
  margin: 0.25rem 0;
  flex: 15em;

  @media screen and (max-width: 1254px) {
    align-items: center;
    max-width: fit-content;
    margin-top: 0.8em;
  }
  & > span {
    font-size: 2.5em;
  }

  &::before {
    content: '=';
    font-size: 2.1rem;
    padding: 0.1em 0;
    @media screen and (max-width: 1252px) {
      opacity: 0;
    }
  }

  &__percent {
    background-color: #6eb90f;
    color: #fff;
    height: min-content;
    width: auto !important;
    padding: 0.1em 0.8em;
    margin: 0 0.4em;
    border-radius: 1.8em;
    font-size: 2.1em;
    align-items: center;
    line-height: normal;
    letter-spacing: -0.01em;
    & > span {
      vertical-align: sub;
    }
    @media screen and (max-width: 480px) {
      font-size: clamp(0.5em, 2em, 10vw);
    }
  }

  &__info {
    text-align: left;

    h4 {
      font-size: 1.5em;
      width: 10em;
      font-family: 'Circular-Std-Bold';
      margin: 0.3em 0;
      @media screen and (max-width: 480px) {
        width: 80%;
        font-size: clamp(0.5em, 1.25em, 4vw);
      }
    }

    span {
      font-family: 'Avenir-Medium';
      font-size: 1em;
      display: inline-block;
      width: 20em;
      line-height: initial;
      @media screen and (max-width: 480px) {
        width: 100%;
        font-size: clamp(0.5em, 1em, 3vw);
      }
    }
  }
}
</style>
