export const riskFreeTypes = [
  { id: 1, name: 'Bajo Riesgo - Bajo Rendimiento' },
  { id: 2, name: 'Mediano Riesgo - Mediano Rendimiento' },
  { id: 3, name: 'Alto Riesgo - Alto Rendimiento' },
];

export const riskPremiumTypes = [
  { id: 4, name: 'Conservador' },
  { id: 5, name: 'Moderado' },
  { id: 6, name: 'Agresivo' },
];

export const betTypes = [
  {
    id: 11,
    name: 'Resultado Final',
  },
];

export const teams = [
  {
    id: 21,
    name: 'TODOS',
  },
];
