<template>
  <div class="selected-user-badge" :style="{ cursor: user.premiumAccount ? 'unset' : 'pointer' }">
    <div class="selected-user-badge-text" @click.stop="onUserTypeClicked">{{ userType }}</div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'ButtonUserType',
  computed: {
    ...mapState('loginGeneral', ['user']),
    userType() {
      return this.user.premiumAccount ? 'Usuario Premium' : 'Usuario Free';
    },
  },
  methods: {
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('general', ['setSectionAnalytics']),
    onUserTypeClicked() {
      if (!this.user.premiumAccount) {
        this.setSectionAnalytics('btn_Free_Tipster');
        this.SET_PREMIUM_MESSAGE(true);
      }
      this.$emit('clicked', this.userType);
    },
  },
};
</script>

<style scoped lang="scss">
.selected-user-badge {
  font-family: 'Circular-Std-Book', serif;
  width: 175px;
  height: 34px;
  padding: 2px 20px 6px;
  border-radius: 13px;
  border: solid 4px #c9e8af;

  & .selected-user-badge-text {
    width: 124px;
    height: 24px;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.74px;
    color: #3e3e3e;
  }
}
</style>
